import * as actions from './actionTypes';

export const defaultState = {
	notifications: [],
};

const notificationReducer = (state = defaultState, action) => {
	const { type, payload } = action;
	switch (type) {
		case actions.NOTIFICATION_INIT:
			return {
				...state,
				notifications: state.notifications.concat({
					id: payload.id,
					initTime: payload.initTime,
					message: payload.message,
					type: payload.type,
					dismissAfter: payload.dismissAfter,
				}),
			};
		case actions.NOTIFICATION_DISMISS:
			return {
				...state,
				notifications: state.notifications.filter(obj => obj.id !== payload.id),
			};
		default:
			return state;
	}
};

export default notificationReducer;
