import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';
import { ErrorPage } from './error-page';

const ErrorBoundary = ({ children, fallbackUI }) => {
	return <SentryErrorBoundary fallback={fallbackUI || <ErrorPage />}>{children}</SentryErrorBoundary>;
};

ErrorBoundary.propTypes = {
	children: PropTypes.node,
	fallbackUI: PropTypes.node,
};

export { ErrorBoundary };
