import NotFoundPage from '../pages/not-found-page/Loadable';
import MapRoadPage from '../pages/map-road/Loadable';

const pageList = {
	mapRoadPage: {
		id: 'map-road',
		path: '/map/road',
		title: 'Map Road',
		component: MapRoadPage,
		exact: true,
	},
	nf: {
		id: '404',
		title: '404',
		path: '*',
		component: NotFoundPage,
	},
};

export default pageList;
