import { StandIcon } from './components/stand-icon';

export const getMainSidebarData = onEmblemClick => [
	{
		title: 'Обслуживание дорог',
		url: '/map/road',
		icon: 'road',
	},
	{
		title: 'Наш Север',
		onClick: onEmblemClick,
		customIcon: StandIcon,
		icon: 'road',
	},
];
