import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import PropTypes from 'prop-types';
import { setSidebarTypeAction } from './actions';
import { getRouterLocationSelector, getSidebarTypeSelector } from './selectors';
import reducer from './reducer';
import { useInjectReducer } from '../../utils/injectReducer';
import { getMainSidebarData } from './sidebar-items';
import { SidebarComponent } from '../../semantic-ui/components/sidebar';
import { PORTAL_URL } from './constants';

export const SideBarWrapper = ({ sidebarType, onSetSidebarType, location }) => {
	useInjectReducer({ key: 'sidebarReducer', reducer });

	const handleEmblemClick = () => {
		window.location.href = PORTAL_URL;
	};

	const sidebarData = useMemo(
		() => ({
			main: getMainSidebarData(handleEmblemClick),
		}),
		[onSetSidebarType],
	);

	return <SidebarComponent sideBarData={sidebarData[sidebarType]} active={location} withBase64Icons stand="smut" />;
};

const mapStateToProps = createStructuredSelector({
	sidebarType: getSidebarTypeSelector(),
	location: getRouterLocationSelector(),
});

const mapDispatchToProps = {
	onSetSidebarType: setSidebarTypeAction,
};

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const ConnectedSideBar = compose(withConnect)(SideBarWrapper);

SideBarWrapper.propTypes = {
	sidebarType: PropTypes.string.isRequired,
	onSetSidebarType: PropTypes.func.isRequired,
	location: PropTypes.string.isRequired,
};

export default ConnectedSideBar;
