/* eslint-disable react/jsx-wrap-multilines */
import React, { useEffect, useState } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import './styles.less';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const SidebarComponent = ({ sideBarData, active, withPopup, withBase64Icons, stand }) => {
	const [isOpen, setIsOpen] = useState(false);

	const handleToggle = () => {
		setIsOpen(!isOpen);
	};

	const handleClick = onMenuClick => {
		if (onMenuClick) {
			onMenuClick();
		} else {
			setIsOpen(false);
		}
	};

	useEffect(() => {
		const handleMenuClose = e => {
			if (e.target && !e.target.classList.contains('sidebar__content_active')) {
				setIsOpen(false);
			}
		};
		if (isOpen) {
			document.addEventListener('click', handleMenuClose);
		}

		return () => {
			document.removeEventListener('click', handleMenuClose);
		};
	}, [isOpen]);

	return (
		<div className="sidebar">
			<div className="sidebar__header" />
			<div className="sidebar__toggle">
				<Icon
					name={isOpen ? 'chevron left' : 'bars'}
					size="big"
					className="sidebar__burger-icon"
					onClick={handleToggle}
				/>
			</div>
			<div className={`sidebar__content ${isOpen ? 'sidebar__content_active' : ''}`}>
				{sideBarData.map(({ title, onClick, url, icon, customIcon: CustomIcon }) => {
					if (CustomIcon) {
						return (
							<Popup
								key={title}
								content={title}
								position="right center"
								trigger={
									<Link to={url} key={title}>
										<div
											className={`sidebar-item ${active === url ? 'sidebar-item__active' : ''} ${
												stand ? `sidebar-item_${stand}` : ''
											}`}
											onClick={() => handleClick(onClick)}
										>
											<CustomIcon />
											<div className="sidebar-item__title-wrapper">{title}</div>
										</div>
									</Link>
								}
							/>
						);
					}
					if (url) {
						return withPopup ? (
							<Popup
								key={title}
								content={title}
								position="right center"
								trigger={
									<Link to={url} key={title}>
										<div
											className={`sidebar-item ${active === url ? 'sidebar-item__active' : ''} ${
												stand ? `sidebar-item_${stand}` : ''
											}`}
											onClick={() => handleClick(onClick)}
										>
											{withBase64Icons ? (
												<i
													className={`sidebar-item__base64-icon ${icon} ${
														active === url ? 'active' : ''
													}`}
												/>
											) : (
												<div className="sidebar-item__icon-wrapper">
													<Icon
														name={icon}
														size="big"
														color={active === url ? null : 'red'}
														inverted={active === url}
													/>
												</div>
											)}
											<div className="sidebar-item__title-wrapper">{title}</div>
										</div>
									</Link>
								}
							/>
						) : (
							<Link to={url} key={title}>
								<div
									className={`sidebar-item ${active === url ? 'sidebar-item__active' : ''} ${
										stand ? `sidebar-item_${stand}` : ''
									}`}
									onClick={() => handleClick(onClick)}
								>
									{withBase64Icons ? (
										<i
											className={`sidebar-item__base64-icon ${icon} ${
												active === url ? 'active' : ''
											}`}
										/>
									) : (
										<div className="sidebar-item__icon-wrapper">
											<Icon
												name={icon}
												size="big"
												color={active === url ? null : 'red'}
												inverted={active === url}
											/>
										</div>
									)}
									<div className="sidebar-item__title-wrapper">{title}</div>
								</div>
							</Link>
						);
					}

					return withPopup ? (
						<Popup
							key={title}
							content={title}
							position="right center"
							trigger={
								<div
									className={`sidebar-item ${active === url ? 'sidebar-item__active' : ''}`}
									onClick={() => handleClick(onClick)}
									key={title}
								>
									{withBase64Icons ? (
										<i
											className={`sidebar-item__base64-icon ${icon} ${
												active === url ? 'active' : ''
											}`}
										/>
									) : (
										<div className="sidebar-item__icon-wrapper">
											<Icon
												name={icon}
												size="big"
												color={active === url ? null : 'red'}
												inverted={active === url}
											/>
										</div>
									)}
									<div className="sidebar-item__title-wrapper">{title}</div>
								</div>
							}
						/>
					) : (
						<div
							className={`sidebar-item ${active === url ? 'sidebar-item__active' : ''} ${
								stand ? `sidebar-item_${stand}` : ''
							}`}
							onClick={() => handleClick(onClick)}
							key={title}
						>
							{withBase64Icons ? (
								<i className={`sidebar-item__base64-icon ${icon} ${active === url ? 'active' : ''}`} />
							) : (
								<div className="sidebar-item__icon-wrapper">
									<Icon
										name={icon}
										size="big"
										color={active === url ? null : 'red'}
										inverted={active === url}
									/>
								</div>
							)}
							<div className="sidebar-item__title-wrapper">{title}</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

SidebarComponent.propTypes = {
	stand: PropTypes.string,
	sideBarData: PropTypes.arrayOf(
		PropTypes.shape({
			title: PropTypes.string.isRequired,
			url: PropTypes.string,
			icon: PropTypes.string.isRequired,
			onClick: PropTypes.func,
		}).isRequired,
	).isRequired,
	active: PropTypes.string.isRequired,
	withPopup: PropTypes.bool,
	withBase64Icons: PropTypes.bool,
};

export { SidebarComponent };
