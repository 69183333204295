import React from 'react';
import './styles.less';

const ErrorIcon = () => {
	return (
		<svg
			className="error-icon"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 229 207"
			data-testid="error-icon"
		>
			<path
				className="error-icon__shape"
				d="M34.5,155.6c-2.1-11,5.1-21.6,16.1-23.7c11-2.1,21.6,5.1,23.7,16.1c2.1,11-5.1,21.6-16.1,23.7
	C47.2,173.8,36.6,166.6,34.5,155.6z"
			/>
			<path
				className="error-icon__shape"
				d="M137.8,73.9c-1.7-4.8,0.9-10.1,5.7-11.8c4.8-1.7,10.1,0.9,11.8,5.7c1.7,4.8-0.9,10.1-5.7,11.8
	C144.7,81.2,139.5,78.7,137.8,73.9z"
			/>
			<path
				className="error-icon__shape"
				d="M51.1,153.6c-0.6-1.8,0.3-3.8,2.1-4.4c1.8-0.6,3.8,0.3,4.4,2.1c0.6,1.8-0.3,3.8-2.1,4.4
	C53.7,156.4,51.8,155.4,51.1,153.6z"
			/>
			<path
				className="error-icon__shape"
				d="M157.7,190.9c4.3,0,7.8-3.5,7.8-7.8s-3.5-7.8-7.8-7.8c-4.3,0-7.8,3.5-7.8,7.8S153.4,190.9,157.7,190.9z"
			/>
			<path
				className="error-icon__shape"
				d="M161.1,114.8h-6.6c-1.4,0-2.5,1.1-2.5,2.5v47.2c0,1.4,1.1,2.5,2.5,2.5h6.6c1.4,0,2.5-1.1,2.5-2.5v-47.2
	C163.6,115.9,162.5,114.8,161.1,114.8z"
			/>
			<path
				className="error-icon__shape"
				d="M96.9,177.2c0.6,0,1.2-0.2,1.8-0.4l4.6-7.6l1.6-4.5c0.2-0.7,0.3-1.4,0.3-2.1c0-0.7-0.2-1.4-0.5-2
	c-0.3-0.6-0.7-1.2-1.3-1.7c-0.5-0.5-1.1-0.8-1.8-1.1l-8.4-3c0.6-7-0.8-14.1-3.9-20.4l6.7-5.9c0.5-0.5,1-1,1.3-1.7s0.5-1.3,0.5-2
	s0-1.4-0.3-2.1c-0.2-0.7-0.6-1.3-1-1.8l-6.2-7.2c-0.5-0.5-1-1-1.6-1.3c-0.6-0.3-1.3-0.5-2-0.6c-0.7,0-1.4,0-2.1,0.3
	c-0.7,0.2-1.3,0.6-1.8,1.1l-6.7,5.9c-5.6-3.8-12.2-6.1-19-6.6l-1.7-8.8c-0.1-0.7-0.4-1.4-0.8-2c-0.4-0.6-0.9-1.1-1.5-1.5
	c-0.6-0.4-1.2-0.7-1.9-0.8c-0.7-0.1-1.4-0.1-2.1,0l-9.3,1.8c-0.7,0.1-1.3,0.4-1.9,0.8c-0.6,0.4-1.1,0.9-1.5,1.5
	c-0.4,0.6-0.7,1.2-0.8,1.9c-0.1,0.7-0.1,1.4,0,2.1l1.7,8.8c-6.1,3-11.4,7.5-15.2,13.2l-8.4-3c-0.7-0.2-1.4-0.3-2.1-0.3
	c-0.7,0-1.4,0.2-2,0.5c-0.6,0.3-1.2,0.7-1.7,1.3c-0.5,0.5-0.8,1.1-1.1,1.8l-3.1,9c-0.2,0.7-0.3,1.4-0.3,2.1c0,0.7,0.2,1.4,0.5,2
	c0.3,0.6,0.7,1.2,1.3,1.7c0.5,0.5,1.1,0.8,1.8,1.1l8.4,3c-0.3,3.5-0.1,7,0.6,10.5c0.7,3.5,1.8,6.8,3.3,10l-6.7,5.9
	c-0.5,0.5-1,1-1.3,1.7c-0.3,0.6-0.5,1.3-0.5,2c0,0.7,0,1.4,0.3,2.1c0.2,0.7,0.6,1.3,1,1.8l6.2,7.2c0.5,0.5,1,1,1.6,1.3
	c0.6,0.3,1.3,0.5,2,0.6c0.7,0,1.4,0,2.1-0.3c0.7-0.2,1.3-0.6,1.8-1.1l6.7-5.9c5.6,3.8,12.1,6.1,18.9,6.6l1.7,8.8
	c0.1,0.7,0.4,1.4,0.8,2c0.4,0.6,0.9,1.1,1.5,1.5c0.6,0.4,1.2,0.7,1.9,0.8c0.7,0.1,1.4,0.1,2.1,0l9.3-1.8c0.7-0.1,1.3-0.4,1.9-0.8
	c0.6-0.4,1.1-0.9,1.5-1.5c0.4-0.6,0.7-1.2,0.8-1.9c0.1-0.7,0.1-1.4,0-2.1l-1.7-8.8c6.1-3,11.4-7.5,15.2-13.2l8.4,3
	C95.5,177.1,96.2,177.2,96.9,177.2z"
			/>
			<path
				className="error-icon__shape"
				d="M141.7,105.5l7.7-12.8c0.9-1.4,2.1-2.6,3.5-3.4c1.5-0.8,3.1-1.3,4.8-1.3c1.7,0,3.3,0.4,4.8,1.3
	c1.5,0.8,2.7,2,3.5,3.4l2.9,4.9c10.9-9.1,15.6-24.4,10.6-38.6c-6.4-18.4-26.5-28.1-44.9-21.7c-18.4,6.4-28.1,26.5-21.6,44.9
	C117.6,95.1,129,103.8,141.7,105.5z"
			/>
			<path
				className="error-icon__shape"
				d="M113,115.6l-4,8.4c-0.3,0.7-0.5,1.4-0.5,2.1c0,0.7,0.1,1.5,0.3,2.1c0.2,0.7,0.6,1.3,1.1,1.9
	c0.5,0.5,1.1,1,1.7,1.3l8.8,4.2c0.7,0.3,1.4,0.5,2.1,0.5c0.3,0,0.6,0,0.8,0l26.1-43.3c0.9-1.4,2.1-2.6,3.5-3.4
	c1.5-0.8,3.1-1.3,4.8-1.3c1.7,0,3.3,0.4,4.8,1.3c1.5,0.8,2.7,2,3.5,3.4l13.7,22.7c4.4-3.3,8.3-7.1,11.5-11.6l8.4,4
	c0.7,0.3,1.4,0.5,2.1,0.5c0.7,0,1.5-0.1,2.1-0.3c0.7-0.2,1.3-0.6,1.9-1.1c0.5-0.5,1-1.1,1.3-1.7l4.2-8.8c0.3-0.7,0.5-1.4,0.5-2.1
	c0-0.7-0.1-1.5-0.3-2.1c-0.2-0.7-0.6-1.3-1.1-1.9s-1.1-1-1.7-1.3l-8.4-4c2-7.4,2.5-15.1,1.3-22.7l8.8-3.1c1.4-0.5,2.5-1.5,3.2-2.8
	c0.6-1.3,0.7-2.9,0.2-4.2l-3.2-9.2c-0.2-0.7-0.6-1.3-1.1-1.9c-0.5-0.6-1.1-1-1.7-1.3c-0.7-0.3-1.4-0.5-2.1-0.5
	c-0.7,0-1.5,0.1-2.2,0.3l-8.8,3.1c-3.8-6.6-9-12.3-15.1-16.9l4-8.4c0.3-0.7,0.5-1.4,0.5-2.1c0-0.7-0.1-1.5-0.3-2.1
	c-0.2-0.7-0.6-1.3-1.1-1.9c-0.5-0.5-1.1-1-1.7-1.3l-8.8-4.2c-0.7-0.3-1.4-0.5-2.1-0.5c-0.7,0-1.5,0.1-2.1,0.3
	c-0.7,0.2-1.3,0.6-1.9,1.1c-0.5,0.5-1,1.1-1.3,1.7l-4,8.4c-7.4-2-15.1-2.5-22.7-1.3l-3.1-8.8c-0.5-1.4-1.5-2.5-2.8-3.2
	c-1.3-0.6-2.9-0.7-4.2-0.2l-9.2,3.2c-1.4,0.5-2.5,1.5-3.2,2.8c-0.6,1.3-0.7,2.8-0.3,4.2l3.1,8.8c-6.6,3.8-12.4,9-17,15.1l-8.4-4
	c-0.7-0.3-1.4-0.5-2.1-0.5s-1.5,0.1-2.1,0.3s-1.3,0.6-1.9,1.1c-0.5,0.5-1,1.1-1.3,1.7l-4.2,8.8c-0.3,0.7-0.5,1.4-0.5,2.1
	c0,0.7,0.1,1.5,0.3,2.1c0.2,0.7,0.6,1.3,1.1,1.9c0.5,0.5,1.1,1,1.7,1.3l8.4,4c-2,7.4-2.5,15.1-1.3,22.7L82.2,82
	c-1.4,0.5-2.5,1.5-3.2,2.8c-0.6,1.3-0.7,2.9-0.2,4.2l3.2,9.2c0.5,1.4,1.5,2.5,2.8,3.2c1.3,0.6,2.9,0.7,4.2,0.2l8.8-3.1
	C101.7,105.3,106.8,111,113,115.6z"
			/>
			<path
				className="error-icon__shape"
				d="M224.1,189.2l-58.1-96.5c-0.9-1.4-2.1-2.6-3.5-3.4c-1.5-0.8-3.1-1.3-4.8-1.3s-3.3,0.4-4.8,1.3
	c-1.5,0.8-2.7,2-3.5,3.4l-58.1,96.5c-0.9,1.5-1.3,3.1-1.4,4.8c0,1.7,0.4,3.4,1.3,4.9c0.8,1.5,2.1,2.7,3.5,3.6
	c1.5,0.9,3.2,1.3,4.9,1.3h116.2c1.7,0,3.4-0.5,4.9-1.3c1.5-0.9,2.7-2.1,3.5-3.6c0.8-1.5,1.3-3.2,1.3-4.9
	C225.5,192.3,225,190.7,224.1,189.2z"
			/>
		</svg>
	);
};

export { ErrorIcon };
