import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import './App.less';
import { ConnectedRouter } from 'connected-react-router';
import moment from 'moment';
import configureStore from './store/createStore';
import Router from './Router';
import history from './utils/history';
import ConnectedSideBar from './pages/sidebar';
import { NotificationList } from './components/notifications-list';
import { ErrorBoundary } from './semantic-ui/components/error-boundary';

moment.locale('ru'); // fixme вынести в глобальный сеттиннг
export const store = configureStore({}, history);

function App() {
	useEffect(() => {
		if (window.location.pathname === '/') {
			history.push('/map/road');
		}
	}, []);

	const appName = 'Портал';
	const buildDate = moment.unix(process.env.REACT_APP_BUILD_DATE).format('DD.MM.YYYY HH:mm');
	const style = 'color: #474a53; background-color: #f8f7f5; font-style: bold;';
	const code = String.fromCodePoint(0x1f63a);
	// eslint-disable-next-line no-console
	console.log(`${code} %c ${appName} от ${buildDate} `, style);
	return (
		<ErrorBoundary>
			<Provider store={store}>
				<ConnectedRouter history={history}>
					<ConnectedSideBar />
					<div className="app-content">
						<NotificationList />
						<Router />
					</div>
				</ConnectedRouter>
			</Provider>
		</ErrorBoundary>
	);
}

export default App;
