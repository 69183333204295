/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import pageList from './pageList';

const Router = () => (
	<Switch>
		{Object.keys(pageList).map(key => {
			const Component = pageList[key].component;
			return (
				<Route
					key={key}
					path={pageList[key].path}
					exact={pageList[key].exact}
					render={props => <Component {...props} />}
				/>
			);
		})}
	</Switch>
);

export default Router;
