export const url = 'https://smut.gov-murman.ru/ajax';
export const POST = 'POST';

export const PORTAL_USER_TOKEN = process.env.REACT_APP_PORTAL_USER_TOKEN;

export const REQUEST_TIMEOUT_MESSAGE = 'Время ожидания ответа истекло';

export const REQUEST_ERROR_CAUSE = 'request-error';

export const UNKNOWN_ERROR_MESSAGE = 'Произошла неизвестная ошибка';
