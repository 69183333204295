import { takeEvery, call, put } from 'redux-saga/effects';
import { captureException } from '@sentry/browser';
import { v4 as uuidv4 } from 'uuid';
import { HANDLE_ERROR } from './actions';
import { notificationInit } from '../notifications/actions';
import { REQUEST_ERROR_CAUSE, UNKNOWN_ERROR_MESSAGE } from '../../api/constants';

function* handleErrorSaga({ error }) {
	if (error.cause !== REQUEST_ERROR_CAUSE) {
		yield call(captureException, error);
	}

	// eslint-disable-next-line no-console
	yield call(console.error, error);

	let errorMessage = UNKNOWN_ERROR_MESSAGE;

	// when error is from request show message request-given message
	if (error || error.message || error.cause === REQUEST_ERROR_CAUSE) {
		errorMessage = error.message;
	}

	yield put(
		notificationInit({
			id: uuidv4(),
			message: errorMessage,
			type: 'warning',
			dismissAfter: 6000,
		}),
	);
}

export default function* errorHandlerSaga() {
	yield takeEvery(HANDLE_ERROR, handleErrorSaga);
}
