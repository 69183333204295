import React from 'react';
import './styles.less';
import { ErrorIcon } from './error-icon';

const ErrorPage = () => {
	const handleReload = () => {
		window.location.reload();
	};

	return (
		<div className="error-page">
			<div className="error-page__container">
				<ErrorIcon />
				<h1 className="error-page__title">Упс! Что-то пошло не так...</h1>
				<p className="error-page__text">
					Пожалуйста, попробуйте{' '}
					<span className="error-page__reload" onClick={handleReload}>
						перезагрузить страницу
					</span>{' '}
					или{' '}
					<a className="error-page__link" href="/">
						вернуться на главную
					</a>
					.
				</p>
			</div>
		</div>
	);
};

export { ErrorPage };
