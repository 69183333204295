import { createSelector } from 'reselect';
import { initialState } from './reducer';
/* eslint-disable implicit-arrow-linebreak */

export const selectSidebarPageDomain = state => state.sidebarReducer || initialState;
export const selectRouterPageDomain = state => state.router || initialState;

export const getRouterLocationSelector = () =>
	createSelector(selectRouterPageDomain, ({ location }) => location.pathname);

export const getSidebarTypeSelector = () => createSelector(selectSidebarPageDomain, ({ sidebarType }) => sidebarType);
