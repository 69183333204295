import * as actions from './actionTypes';

export const notificationInit = data => ({
	type: actions.NOTIFICATION_INIT,
	payload: data,
});

export const notificationDismiss = data => ({
	type: actions.NOTIFICATION_DISMISS,
	payload: data,
});
