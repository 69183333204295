import { SET_SIDEBAR_TYPE } from './actions';

export const initialState = {
	sidebarType: 'main',
};

const newsReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case SET_SIDEBAR_TYPE:
			return {
				...state,
				sidebarType: payload,
			};
		default:
			return state;
	}
};

export default newsReducer;
